import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Style pour le sélecteur de date
import 'react-date-range/dist/theme/default.css';
import PeopleIcon from "../../icons/PeopleIcon";
import TableIcon from "../../icons/TableIcon";
import { Box, Button, Modal } from "@mui/material";
import ReservationList from "../reservation/ReservationList";
import AddOrEditReservationButton from "./AddOrEditReservationButton";
import ListIcon from "../../icons/ListIcon";
import { useIsMobile } from "../../hooks/useIsMobile";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  backgroundColor : '#ffffff',
  p: 4,
};

const CalendarSlot = ({ reservations, dayOfWeek }) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const isMobile = useIsMobile()

  const handleClose = () => {
    setOpen(false);
  }


  const [groupedReservations, setGroupedReservations] = useState()
  const [reservationNumber, setReservationNumber] = useState(0)
  const [slotTime, setSlotTime] = useState(0)
  const [slotType, setSlotType] = useState()
  const [totalPerson, setTotalPerson] = useState(0)
  const [reservationType, setReservationType] = useState(0)
  const [reservationTypesS, setReservationTypesS] = useState(0)

  const [backgroundColor, setBackgroundColor] = useState("")

  useEffect(() => {
    if(reservations) {
      setReservationNumber(reservations.length)
      setSlotTime(reservations[0].time)

      let personNumber = 0;
      let reservationTypes = "";
      reservations.map((reservation) => {
        personNumber= personNumber + reservation.numberOfPeople
        reservationTypes = reservationTypes.concat(" " + reservation.type)
      })
      setTotalPerson(personNumber)
      setReservationType(reservations[0].type)
      setReservationTypesS(reservationTypes)

      let type = reservations[0].type;
      if(type) {
        if (type.includes("classique")) {
          setReservationType("Classique")
        } else if (type.includes("express")) {
          setReservationType("Express")
        } else if (type.includes("express")) {
          setReservationType("Express")
        }  else if (type.includes("brunch")) {
          setReservationType("Brunch")
        }
        else if (type.includes("gouter")) {
          if(reservations[0].time.substring(0,2) > 14) {
            setReservationType("Gouter")
          } else {
            setReservationType("Ptit dej")
          }

        }
      }

      if (personNumber < 5 ) {
        setBackgroundColor("#C5E1A5")
      } else if (personNumber >= 5 && personNumber < 10) {
        setBackgroundColor("#FFF9C4")
      } else if (personNumber >= 10 && personNumber < 15) {
        setBackgroundColor("#FFCCBC")
      } else if (personNumber >= 16) {
        setBackgroundColor("#E57373")
      }
    }

  }, [reservations]);



  return (
    <>

      <div style={{display : "flex", flexDirection : "row", border : "1px solid black", width : "100%", padding : "1rem", backgroundColor: backgroundColor}} className="calendar-slot">
        <div style={{display : "flex", flexDirection : "Column", width : "100%"}}>
          <div>
            <p style={{padding : 0, margin : 0}}>{reservationType}</p>
          </div>
          <div>
            {slotTime}
          </div>
          <div style={{ display : "flex", alignItems: "center", gap :"0.5rem", fontWeight : "bold" }}>
            {totalPerson} <PeopleIcon/>
            {reservationNumber} <TableIcon/>
          </div>
        </div>
        <div
          style={{ marginBottom : "0rem", marginLeft : "1rem", gap : "1rem", display : "flex", flexDirection : "column"}}
        >
          <AddOrEditReservationButton dayOfWeek={dayOfWeek} reservation={{date : reservations[0].date, time : slotTime, type : reservations[0].type}} isEdit={false}/>
          <div onClick={setOpen} style={{ border : "1px solid black", borderRadius : "5px", cursor : "pointer", display : "flex", alignItems : "center", justifyContent : "center", backgroundColor : "white", padding : "0.1rem"}}>
            <ListIcon/>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-slot"
        aria-describedby="modal-modal-slot-description"
        sx={{ position : "", justifyContent : "center", alignItems : "center", display : "flex", width : "100vw"}}
      >
        <Box style={{backgroundColor : "white", width : "80%"}} sx={style}>
          <ReservationList handleClose={handleClose} dayOfWeek={dayOfWeek} reservations={reservations} reservationType={reservationType}/>
          <Button variant="outlined" color="primary" onClick={() => setOpen(false)} style={{margin :" 1rem", marginLeft : "0", marginBottom : "0"}}>Fermer</Button>
        </Box>
      </Modal>
    </>
  );
};

export default CalendarSlot;
