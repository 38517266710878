import { useIsMobile } from "../../hooks/useIsMobile";
import React from "react";
import ReservationCard from "./ReservationCard";
import { deleteReservation } from "../../services/ceramique-reservation";


const ReservationList = ({reservations, reservationType, dayOfWeek}) => {

  const { isMobile } = useIsMobile();

  return (
    <div style={{
      color: "black",
      marginBottom: "0.5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap : "1rem",
      width : "100%",
      height : "90%",
    }}>
      <h1 style={{ color : "#973b2c", fontWeight : "bold"}}>Créneau {reservationType}</h1>
      <div style={{
        overflowY : "auto",
        width : "100%",
        maxHeight : "70vh",
        gap : "1rem",
        display: "flex",
        flexDirection: "column",
      }}>
        {reservations.map((reservation, index) => {
          return (
            <ReservationCard reservation={reservation} key={"resa-card" + index} dayOfWeek={dayOfWeek}/>
          );
        })
        }
      </div>
    </div>
  )
}


export default ReservationList
