import React, { useEffect, useState } from "react";
import Layout from '../../components/layout/Layout'
import { useIsMobile } from "../../hooks/useIsMobile";
import { getReservationOfTheWeek } from "../../services/ceramique-reservation";
import { HonestWeekPicker } from "../../components/calendrier/WeekPicker";
import moment from "moment";
import { endOfWeek, startOfWeek } from "date-fns";
import CalendarDay from "../../components/calendrier/CalendarDay";
import { ArrowLeft } from "../../icons/ArrowLeft";
import { ArrowRight } from "../../icons/ArrowRight";
import { useReservations } from "../../context/CalendrierContext";

const imageInfo1 = {
  "alt": "Une photo de linogravure réalisé en atelier",
  "image": "/img/v2/Lautresateliers-min.jpg",
  "maxWidth": 250
}

const imageInfo2 = {
  "alt": "Une photo de la boutique de l atelier forma",
  "image": "/img/v2/Lautresateliers2-min.jpg.jpeg",
  "maxWidth": 250
}

const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche', ];

const CalendrierPage = () => {
  moment.locale('fr');

  const { reservations, addReservation, removeReservation, editReservation, updateAllReservations, updateWeek } = useReservations();


  const [week, setWeek] = useState({
    firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
    lastDay: endOfWeek(new Date(), { weekStartsOn: 1 })
  });

  const onChange = (week) => {
    setWeek(week);
    updateWeek(week)
    getReservationOfTheWeek(week).then((response) => {
      updateAllReservations(response)
    })
  };


  useEffect(() => {
    moment.locale('fr');
  }, [week])

  const minusOneWeek = () => {
    const firstDayOfCurrentWeek = moment(week.firstDay);
    const lastDayOfCurrentWeek = moment(week.lastDay);
    const firstDayOfPreviousWeek = firstDayOfCurrentWeek.clone().subtract(1, 'week');
    const lastDayOfPreviousWeek = lastDayOfCurrentWeek.clone().subtract(1, 'week');

    const newWeek = {
      firstDay: firstDayOfPreviousWeek.toDate(),
      lastDay: lastDayOfPreviousWeek.toDate()
    };
    onChange(newWeek)
  }

  const plusOneWeek = () => {
    const firstDayOfCurrentWeek = moment(week.firstDay);
    const lastDayOfCurrentWeek = moment(week.lastDay);
    const firstDayOfNextWeek = firstDayOfCurrentWeek.clone().add(1, 'week');
    const lastDayOfNextWeek = lastDayOfCurrentWeek.clone().add(0, 'week').add(7, "days");

    const newWeek = {
      firstDay: firstDayOfNextWeek.toDate(),
      lastDay: lastDayOfNextWeek.toDate()
    };
    onChange(newWeek)

  }

  return (
    <Layout>
      <section className="section" style={{backgroundColor : "#ffffff", paddingTop : "0rem"}}>
        <div className="container">
          <div className="content">
            <div className="calendrier-header">
              <div onClick={() => minusOneWeek()} className="arrow-container">
                <ArrowLeft size={"50px"}/>
              </div>
              <HonestWeekPicker onChange={onChange} week={week} setWeek={setWeek} firstDateSelected={week.firstDay} />
              <div onClick={() => plusOneWeek()} className="arrow-container" style={{}}>
                <ArrowRight size={"50px"}/>
              </div>
            </div>

            <div className="grid grid-container">
              {daysOfWeek.map((day, index) => {
                let dateOfDay = moment(week.firstDay).add(index, "days")
                if(day != "Lundi" && day != "Dimanche") {
                  return (
                    <div className="day cell" key={"day-cell-" + day}>
                      <div className="day-header" key={"day-header-" + day}>
                        {day} {dateOfDay.toDate().getDate()}
                      </div>
                      <CalendarDay reservations={reservations[day]} dayOfWeek={day} day={dateOfDay} key={"calendar-day-" + daysOfWeek} />
                    </div>
                  )
                }
              })
              }
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )}


export default CalendrierPage
