import { useIsMobile } from "../../hooks/useIsMobile";
import React, { useEffect, useState } from "react";
import TrashIcon from "../../icons/TrashIcon";
import AddOrEditReservationButton from "../calendrier/AddOrEditReservationButton";
import PeopleIcon from "../../icons/PeopleIcon";
import { deleteReservation, editCeramiqueReservation, saveCeramiqueReservation } from "../../services/ceramique-reservation";
import { FormLabel, Grid } from "@mui/material";
import { useReservations } from "../../context/CalendrierContext";
import toast from "react-hot-toast";


const ReservationCard = ({reservation, dayOfWeek}) => {

  const { isMobile } = useIsMobile();

  const { removeReservation } = useReservations();

  const { editReservationFormaComment } = useReservations();

  const [formaComment, setFormaComment] = useState(reservation.formaComment)

  useEffect(() => {
    setFormaComment(reservation.formaComment)
  }, [reservation])

  const handleDeleteReservation = async (reservation) => {
    if (window.confirm("Etes vous sur de vouloir supprimer cette réservation ?")) {
      await deleteReservation(reservation).then((response) => {
        if (response.data) {
          removeReservation(dayOfWeek, reservation._id)
          toast.success("Réservation supprimée.")
        } else {
          console.log("error deleteResv :", response)
          toast.error("Erreur lors de la suppression : ", response)
        }
      })
    }
  }


  const updateFormaComment = () => {
    reservation.formaComment = formaComment
    editCeramiqueReservation(reservation, false).then((response) => {
      if (response) {
        editReservationFormaComment(dayOfWeek, reservation._id, reservation)
        toast.success("Réservation mise à jour.")
      } else {
        toast.error("Erreur édition réservation : ", response)
      }
    })
  }

  const handleFormaCommentChange = (event) => {
    const value = event.target.value;
    setFormaComment(value);
  };

  return (
    <div style={{
      color: "black",
      backgroundColor : "#dadada",
      // border : "1px solid #973b2c",
      marginBottom: "0rem",
      display: "flex",
      justifyContent: "left",
      flexDirection: "column",
      alignItems: "left",
      width : "100%",
      padding : "1rem"
    }}>
      <Grid container spacing={1}>
        <Grid item xs={2} md={2}>
          <FormLabel>{"x" + reservation.numberOfPeople} <PeopleIcon/></FormLabel>
        </Grid>
        <Grid item xs={2} md={2}>
          {" " + reservation.time}
        </Grid>
        <Grid item xs={4} md={2}>
          {" " + reservation.name}
        </Grid>
        <Grid item xs={4} md={2}>
          {reservation.telephone}
        </Grid>
        <Grid item xs={6} md={2}>
          <div style={{maxWidth : "80px"}}>
            <input
              type="text"
              contentEditable={true}
              value={formaComment}
              onBlur={updateFormaComment}
              onChange={handleFormaCommentChange}
            >
            </input>
          </div>
        </Grid>
        <Grid item xs={6} md={2}>
          <div style={{ flexDirection : "row", display : "flex", gap : "0.5rem", justifyContent : "flex-end"}}>
            <AddOrEditReservationButton reservation={reservation} isEdit={true} dayOfWeek={dayOfWeek}/>
            <div onClick={() => handleDeleteReservation(reservation)} style={{ border : "1px solid black", borderRadius : "5px", cursor : "pointer", display : "flex", alignItems : "center", justifyContent : "center", backgroundColor : "white", padding : "0.1rem"}}>
              <TrashIcon/>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} md={4}>
          {" " + reservation.email}
        </Grid>
        <Grid item xs={12} md={6}>
          {reservation.comment}
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
    </div>
  )
}


export default ReservationCard
