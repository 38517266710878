import React, { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // Style pour le sélecteur de date
import 'react-date-range/dist/theme/default.css';
import CalendarSlot from "./CalendarSlot";
import AddOrEditReservationButton from "./AddOrEditReservationButton";
import { useReservations } from "../../context/CalendrierContext"; // Thème par défaut

const CalendarDay = ({ reservations, dayOfWeek, day }) => {

  const [reservationNumber, setReservationNumber] = useState(0)
  const [slotsNumber, setSlotsNumber] = useState(0)
  const [totalPerson, setTotalPerson] = useState(0)
  const [groupedReservations, setGroupedReservations] = useState({})


  const getCreneau = (time) => {
    const hour = time.split(':')[0];
    return `${hour}:00-${hour}:59`;
  };

  useEffect(() => {
    const groupedReservationsBySlot = {};
    let personNumber = 0;
    let slotsNumberC = 0;

    if(reservations) {
      let brunchFirstTime = "";
      let brunchFirstTimeSet = false;
      reservations.forEach(reservation => {
        if(reservation.type === "brunch" && !brunchFirstTimeSet) {
          brunchFirstTime = reservation.time
          brunchFirstTimeSet = true
        }
        personNumber = personNumber + reservation.numberOfPeople;
        let creneau = getCreneau(reservation.time);
        let type = reservation.type;
        if(reservation.type === "brunch") {
          creneau = getCreneau(brunchFirstTime)
        }
        if (!groupedReservationsBySlot[creneau+ "-" + type]) {
          slotsNumberC++
          groupedReservationsBySlot[creneau+ "-" + type] = [];
        }
        groupedReservationsBySlot[creneau+ "-" + type].push(reservation);
      });
      setGroupedReservations(groupedReservationsBySlot)
      setSlotsNumber(slotsNumberC)
      setReservationNumber(reservations.length)
      setTotalPerson(personNumber)
    }
  }, [reservations]);



  return (
    <div style={{display : "flex", flexDirection : "column", width : "100%", gap : "1rem"}}>

      {Object.keys(groupedReservations).map((creneau, index) => {
        return (
          <CalendarSlot reservations={groupedReservations[creneau]} key={index + "-calendar-slot"} dayOfWeek={dayOfWeek} timeSlot={creneau}/>
        )
      })}

      {reservationNumber === 0 && dayOfWeek != "Lundi" && dayOfWeek != "Dimanche" &&
      <div style={{justifyContent : "center", alignItems : "center", display : "flex", flexDirection : "column"}}>
        <AddOrEditReservationButton reservation={{date : day}} isEdit={false} dayOfWeek={dayOfWeek}/>
      </div>
      }
    </div>
  );
};

export default CalendarDay;
