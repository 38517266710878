import React from 'react';

const TableIcon = (props) => {
 return (
<svg fill="#000000" height="1.5rem" width="1.5rem" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
	 viewBox="0 0 421.4 421.4" space="preserve">
<g>
	<path d="M148.003,223.375h-41.436l-2.515,22h32.95v22.5h-35.521l-2.514,22h38.036v33.5c0,6.075,4.925,11,11,11
		c6.075,0,11-4.925,11-11v-89C159.003,228.3,154.078,223.375,148.003,223.375z"/>
	<path d="M43.212,245.375h25.613l2.515-22H42.954c-0.614-15.8-2.131-32.399-4.557-49.771c-6.301-45.118-16.46-78.109-16.889-79.491
		c-1.802-5.802-7.968-9.043-13.768-7.242c-5.802,1.802-9.044,7.966-7.242,13.768c0.399,1.286,39.585,129.617,9.092,219.192
		c-1.958,5.751,1.117,12,6.868,13.958c1.175,0.4,2.37,0.59,3.546,0.59c4.579,0,8.855-2.882,10.412-7.458
		c3.909-11.483,6.927-23.844,9.053-37.045h24.27l2.515-22H42.123C42.726,260.596,43.085,253.091,43.212,245.375z"/>
	<path d="M413.66,86.87c-5.803-1.805-11.966,1.439-13.768,7.242c-0.429,1.382-10.588,34.373-16.889,79.491
		c-2.426,17.372-3.943,33.972-4.557,49.771h-28.386l2.515,22h25.613c0.127,7.716,0.486,15.221,1.089,22.5h-24.131l2.514,22h24.271
		c2.126,13.201,5.144,25.561,9.053,37.045c1.558,4.576,5.832,7.458,10.412,7.458c1.175,0,2.372-0.189,3.546-0.59
		c5.751-1.958,8.826-8.207,6.868-13.958c-30.428-89.381,8.637-217.72,9.092-219.192C422.705,94.836,419.462,88.672,413.66,86.87z"/>
	<path d="M284.397,267.875v-22.5h32.95l-2.515-22h-41.435c-6.075,0-11,4.925-11,11v89c0,6.075,4.925,11,11,11s11-4.925,11-11v-33.5
		h38.035l-2.514-22H284.397z"/>
	<path d="M336.587,162.727h8.612c9.391,0,17.003-7.611,17.003-17c0-9.391-7.612-17.004-17.003-17.004H76.201
		c-9.391,0-17.002,7.613-17.002,17.004c0,9.389,7.611,17,17.002,17h8.612L66.521,322.778c-0.69,6.036,3.644,11.488,9.68,12.178
		c6.038,0.696,11.488-3.644,12.178-9.68l18.577-162.55h207.488l18.577,162.55c0.641,5.612,5.399,9.752,10.915,9.752
		c0.417,0,0.839-0.023,1.263-0.072c6.036-0.689,10.37-6.142,9.68-12.178L336.587,162.727z"/>
</g>
</svg>
  );
};

export default TableIcon;
